.App {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: center;
  justify-items: stretch;
  padding: 0.5rem;
}

h2{
  border-bottom: 0.3rem solid #ffdc00;
  display: inline-block;
}

.field {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 0;
  row-gap: 8px;
  max-width: 300px;
}

.field.row {
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 0.5rem;
}

.field label {
  font-size: 0.875rem;
}

.field input[type=text], .field input[type=date], .field select {
  width: 100%;
  padding: 0.75rem 0.75rem;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  outline-color: #3e109b;
}

input[type=submit] {
  width: 100%;
  background-color: #f800b7;
  color: white;
  padding: 0.75rem 0.75rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.875rem;
  text-transform: uppercase;
}

input[type=submit]:hover {
  background-color: #ac007f;
}

.error {
  font-size: 12px;
  color: #a80c0c;
  display: none;
}

.no_data {
  display: flex;
  justify-content: center;
}

.chips_block {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.chip {
  display: inline-block;
  padding: 0 0.75rem;
  height: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  border-radius: 1.5rem;
  background-color: #e5e5e5;
}

.close_btn {
  margin-left: 0.375rem;
  margin-right: -0.3rem;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.25rem;
}

.close_btn:hover {
  color: #000;
}

.field .accept_all {
  font-size: 0.75rem;
}

.add_user {
  display: inline-table;
}

.add_user, .user_list {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
}

.user_items{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 0.3rem;
  overflow: auto;
}

.user_items > span {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
  font-size: 0.875rem;
}

.user_items span:nth-child(-n+6) {
  background-color: #3e109b;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
}